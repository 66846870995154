import { getMessaging, onMessage, getToken } from 'firebase/messaging';
import { showLoading, closeLoading, showError, closeError } from './main';


var languages = [];
var labelsLocale = [];
var lang = "en";
var sufix = "atstorm";

import { messaging } from "../js/main.js";

export function getLang() {
    var lang = null;
    if (window.navigator.languages && window.navigator.languages > 0) {
        lang = window.navigator.languages[0];
    }
    else {
        lang = window.navigator.userLanguage || window.navigator.language;
    }

    if (lang.indexOf("-") != -1) {
        lang = lang.split("-")[0];
    }


    //lang = "pt";
    if (lang == null)
        lang = "en";

    return lang;
}


//Funcion que devuelve la URL de la configuracion
function getURLConfig() {
    var control = window.localStorage.getItem("control_local" + sufix);
    var controlJSON = JSON.parse(control);
    var url = controlJSON["control"]["CtlUrlWS"];

    return url;
}

export function getPrincipal() {

    var localeJSON = JSON.parse(window.localStorage.getItem("locale" + sufix));

    $("#dispmenu").css("display", "none");
    for (var key in localeJSON) {
        languages[key] = key;
    }
    var lang = getLang();

    for (var key in localeJSON) {
        if (key == lang) {
            var obj = localeJSON[key];
            for (keytrans in obj["translations"]) {
                var sectiontrans = obj["translations"]["common"];

                for (keylabel in sectiontrans) {
                    labelsLocale[keylabel] = sectiontrans[keylabel];
                }
            }
        }
    }

    $.ajax({
        url: "principal.html",

        crossDomain: true,
        cache: false,
        headers: {
            "cache-control": "no-cache"
        },
        success: async function (data) {

            //StatusBar.backgroundColorByHexString("#666666");
            //$("#body").css("background-color","#FFFFFF")
            /* $("#body").css("background-color","red")
             $("#contentAPP").html("hola");
 
             //Labels menú
             $("#labelmenuuser").html(labelsLocale["user"]);
             $("#labelmenudevices").html(labelsLocale["devices"]);
             $("#labelmenuabout").html(labelsLocale["about"]);
             $("#labelmenuexit").html(labelsLocale["exit"]);
 
 
             //Labels leyenda
             $("#labellegendtitle").html(labelsLocale["legend"]);
             
             //Labels new version
             $("#labelnewvesion").html(labelsLocale["new_version"]);
             $("#linkdownloadApp").html(labelsLocale["download_here"]);
             $("#linkdownloadApp").attr("href", window.localStorage.getItem("CtlUrlVersion" + sufix));
 
             $("#labelerror").html(labelsLocale["unexpected_error"]);
             alert(data);*/
            if (window.localStorage.getItem("user" + sufix) == null) {
                getLoginHTML();
            }
            else {
                // $("#menu").html(getMenuHTML(2,null));
                var response = await registerToken().then(function (data) {
                    // Print device token to console
                    //alert("Register");
                    getDevices();
                    //reject("ee");
                    //resolve(deviceToken);

                    // Succeeded, optionally do something to alert the user
                }).catch(function (err) {
                    // Notify user of failure
                    alert('Registration failed IOT: ' + err);
                    window.localStorage.removeItem('user' + sufix)
                });
                // console.log(token);
                //getDevices();
            }

        },
        error: function (jqXHR, textStatus, errorThrown) {
            alert("Error 4");
        },
        complete: function (data, textStatus, errorThrown) {
        }
    });
}


async function getLoginHTML() {
    $.ajax({
        url: "login.html",
        crossDomain: true,
        cache: false,
        headers: {
            "cache-control": "no-cache"
        },
        success: function (data) {
            //StatusBar.backgroundColorByHexString("#666666");
            $("#body").css("background-color", "#FFFFFF")
            $("#containerAPP").html(data);
            $("#test").addClass("hideTotal");

            $("#access").html(labelsLocale["access"]);
            $("#login").attr("placeholder", labelsLocale["user"]);
            $("#pass").attr("placeholder", labelsLocale["password"]);
            $("#acceder").val(labelsLocale["login"]);
            $("#allowNotifications").val(labelsLocale["allow_push"]);
            $("#push_mandatory").html(labelsLocale["allow_push_mandatory"]);

            if (window.localStorage.getItem('haslogo' + sufix) != null && window.localStorage.getItem('haslogo' + sufix) == "true")
                $("#logoempresalogin").attr("src", window.localStorage.getItem("imglogo" + sufix));

            $("#allowNotifications").click(function () {
                registerNotificactions();
            });

            $("#acceder").click(async function () {
                if ($("#login").val().length != 0 && $("#pass").val().length != 0) {
                    //registerNotificactions();
                    var token = window.localStorage.getItem('tokenfirebase');
                    if (token != null && token != "" && token != "undefined") {
                        showLoading();
                        loginApp();
                    }
                    else {
                        alert(labelsLocale["allow_push_mandatory"]);
                    }
                }
                else {
                    alert(labelsLocale["fields_mandatory"]);
                }

                /*showLoading();
                var token = await registerPushy();
                console.log(token);

                //$("#token").html(token);
                
                var url = window.localStorage.getItem("CtlUrlWSIoT" + sufix) + "/login/" + $("#login").val() + "/" + $("#pass").val();

                $.ajax({
                    type: 'post',
                    url: url,
                    crossDomain: true,
                    async:true,
                    cache: false,
                    headers: {
                        "cache-control": "no-cache"
                    },
                    cache: false,
                    contentType: "application/json",
                    success: function(data) {
                        if(data != "" && data.length > 0){
                            window.localStorage.setItem('user'+ sufix, JSON.stringify(data[0]));
                            //var img = getPath() + "/img/logo_atstorm.svg";
                            var img = "/img/logo_atstorm.svg";
                            var haslogo = false;
                            if(data[0]["logo"] != null){
                                haslogo = true;
                                img = window.localStorage.getItem("CtlUrlLogos" + sufix) + "/" + data[0]["logo"];
                            }
                            window.localStorage.setItem('haslogo'+ sufix, haslogo);
                            
                            toDataURL(img, async function(dataUrl) {
                                window.localStorage.setItem("imglogo" + sufix, dataUrl);
                                //getPrincipalHTML();
                               
                                var response = await registerToken().then(function (data) {
                                // Print device token to console
                                console.log('Data: ' + data);
                                getDevices();
                                //reject("ee");
                                //resolve(deviceToken);
                               
                                // Succeeded, optionally do something to alert the user
                            }).catch(function (err) {
                                // Notify user of failure
                                alert('Registration failed IOT: ' + err);
                                window.localStorage.removeItem('user'+ sufix)
                            });
                               

                                
                                //getDevices();
                            });
                        }
                        else{
                            showError(labelsLocale["error_login"]);
                        }
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        showError(labelsLocale["error_login"]);
                    },
                    complete: function(data, textStatus, errorThrown){
                        closeLoading();
                    }
                });*/

            });
        },
        error: function (jqXHR, textStatus, errorThrown) {
            // alert("error cargando 2");
            //alert("error2");
        },
        complete: function (data, textStatus, errorThrown) {
            //alert("Complete 2");
            //getTemplateControl();
            /*
            showScreen(1);
            loadData();*/


        }
    });
}

function getDeviceHTML(device) {
    $.ajax({
        url: "principal.html",
        crossDomain: true,
        cache: false,
        headers: {
            "cache-control": "no-cache"
        },
        success: function (data) {
            console.log(device);
            $("#containerAPP").html(data);
            showLoading();
            var userbbdd = window.localStorage.getItem("useratstorm");

            var user = JSON.parse(userbbdd);
            var id = device.id;
            var url = window.localStorage.getItem("CtlUrlWSIoT" + sufix) + "/getDispositivoWithAlarms/" + id + "/" + user.id;;
            // var url = "http://localhost:8000/WS_REST/getDispositivoWithAlarms/" + id + "/" + user.id; 

            //    alert(url);
            $.ajax({
                type: 'post',
                url: url,
                crossDomain: true,
                async: true,
                cache: false,
                headers: {
                    "cache-control": "no-cache"
                },
                cache: false,
                contentType: "application/json",
                success: function (data) {
                    if (data != null) {
                        $("#legendAlertaAT").html(labelsLocale["alertlevel"]);
                        $("#labeldevice").html(data.device.descripcion);
                        $("#textAlarm").html(getTextAlarm(data.device.ultimo_estado));
                        $("#circulo").css("border-color", getColorAlarm(data.device.ultimo_estado));
                        $("#fieldsetAlerta").css("border-color", getColorAlarm(data.ultimo_estado));

                        $("#token").val(window.localStorage.getItem("tokenfirebase"));
                        $("#iddispositivo").val(data.device.id);

                        if (data.alarms != 0) {
                            $("#btnAceptarEvento").css("display", "block");
                        }

                        $("#btnAceptarEvento").html(labelsLocale["acceptAlarm"]);
                        //$("#iddispositivo").val(27);
                    }
                    else {
                        showError(labelsLocale["error_login"]);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    alert("error");
                    showError(labelsLocale["error_login"]);
                },
                complete: function (data, textStatus, errorThrown) {
                    closeLoading();
                }
            });
        }
    });
}

function registerNotificactions() {
    if ('safari' in window && 'pushNotification' in window.safari) {
        const permissionData = window.safari.pushNotification.permission('web.systems.at3w-connect');
        if (permissionData.permission === 'default') {
            //alert("Has Permission");
            // Request permission
            window.safari.pushNotification.requestPermission(
                'web.systems.at3w-connect', // your push ID
                'devpwafirebase.at3w-connect.systems', // your website domain
                {}, // data
                (permissionData) => {
                    if (permissionData.permission === 'granted') {
                        console.log('Safari Push Notifications permission granted');
                        // Handle subscription
                    } else if (permissionData.permission === 'denied') {
                        console.error('Safari Push Notifications permission denied');
                    }
                });
        } else if (permissionData.permission === 'granted') {
            console.log('Safari Push Notifications permission already granted');
            // Handle subscription
        }
    }
    else {
        if (!("Notification" in window)) {
            // Check if the browser supports notifications
            alert("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
            // Check whether notification permissions have already been granted;
            // if so, create a notification

            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log(messaging);
                    getToken(messaging, { vapidKey: 'BDPG_nAmXAiSMdFro6ntcVsujM-rYH1rkkncZ3EnM2Ljq1dZO5xOosFGtMn6vNsTLpzZI1xLKD6czOPXJ1jCnwE' }).then((token) => {
                        console.log('Token:', token);
                        window.localStorage.setItem('tokenfirebase', token);
                        //loginApp();
                        //registerToken(token);
                        // Tu lógica aquí para manejar el token
                        //TokenElem.innerHTML = "token is : " + token;
                    });
                }
            });
            // …
        } else if (Notification.permission !== "denied") {
            // We need to ask the user for permission
            Notification.requestPermission().then((permission) => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    //const notification = new Notification("Hi there!");
                    //getToken().then((token) => {
                    getToken(messaging, { vapidKey: 'BDPG_nAmXAiSMdFro6ntcVsujM-rYH1rkkncZ3EnM2Ljq1dZO5xOosFGtMn6vNsTLpzZI1xLKD6czOPXJ1jCnwE' }).then((token) => {
                        console.log(token);
                        // alert("Return token " + token);
                        window.localStorage.setItem('tokenfirebase', token);
                        // loginApp();
                        //registerToken(token);
                        //const notification = new Notification("Hi there!");
                        // TokenElem.innerHTML = "token is : " + token
                    });
                }
            });
        }
        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them anymore.
    }
}


function loginApp() {
    var url = window.localStorage.getItem("CtlUrlWSIoT" + sufix) + "/login/" + $("#login").val() + "/" + $("#pass").val();
    $.ajax({
        type: 'post',
        url: url,
        crossDomain: true,
        async: true,
        cache: false,
        headers: {
            "cache-control": "no-cache"
        },
        cache: false,
        contentType: "application/json",
        success: function (data) {
            if (data != "" && data.length > 0) {
                window.localStorage.setItem('user' + sufix, JSON.stringify(data[0]));
                //var img = getPath() + "/img/logo_atstorm.svg";
                var img = "/img/logo_atstorm.svg";
                var haslogo = false;
                if (data[0]["logo"] != null) {
                    haslogo = true;
                    img = window.localStorage.getItem("CtlUrlLogos" + sufix) + "/" + data[0]["logo"];
                }
                window.localStorage.setItem('haslogo' + sufix, haslogo);

                toDataURL(img, async function (dataUrl) {
                    window.localStorage.setItem("imglogo" + sufix, dataUrl);
                    //getPrincipalHTML();

                    var response = await registerToken().then(function (data) {
                        // Print device token to console
                        //  closeLoading();
                        getDevices();
                        //reject("ee");
                        //resolve(deviceToken);

                        // Succeeded, optionally do something to alert the user
                    }).catch(function (err) {
                        // Notify user of failure
                        alert('Registration failed IOT: ' + err);
                        window.localStorage.removeItem('user' + sufix)
                    });
                    //getDevices();
                });
            }
            else {
                closeLoading();
                alert("Error Login");
                showError(labelsLocale["error_login"]);
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            closeLoading();
            console.log(textStatus);
            console.log(errorThrown);
            alert("Error");
            showError(labelsLocale["error_login"]);
        },
        complete: function (data, textStatus, errorThrown) {
            //closeLoading();
        }
    });
}

function registerToken() {
    return new Promise((resolve, reject) => {
        var token = window.localStorage.getItem('tokenfirebase');
        var user = JSON.parse(window.localStorage.getItem('user' + sufix));

        var url = window.localStorage.getItem("CtlUrlWSIoT" + sufix) + "/registerUserToken/" + user.id + "/" + token;

        //var url = window.localStorage.getItem("CtlUrlWSIoT" + sufix)  + "/registerUserToken/3/" + token;
        $.ajax({
            type: 'post',
            url: url,
            crossDomain: true,
            async: true,
            cache: false,
            headers: {
                "cache-control": "no-cache"
            },
            contentType: "application/json",
            success: function (data) {
                resolve(data);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                reject("error");
            },
            complete: function (data, textStatus, errorThrown) {
                closeLoading();
            }
        });
    });
}

function getPath() {
    var p = window.location.pathname;
    return p.substring(0, p.lastIndexOf('/'));
}

function toDataURL(url, callback) {
    var httpRequest = new XMLHttpRequest();
    httpRequest.onload = function () {
        var fileReader = new FileReader();
        fileReader.onloadend = function () {
            callback(fileReader.result);
        }
        fileReader.readAsDataURL(httpRequest.response);
    };
    httpRequest.open('GET', url);
    httpRequest.responseType = 'blob';
    httpRequest.send();
}

function getColorAlarm(status) {
    var color = "#808080";
    switch (status) {
        case 0:
            color = "#3ec222";
            $("#btnAceptarEvento").css("display", "none");
            break;
        case 1:
            color = "#ffdc00";
            $("#btnAceptarEvento").css("display", "block");
            break;
        case 2:
            color = "#ff8900";
            $("#btnAceptarEvento").css("display", "block");
            break;
        case 3:
            color = "#ff1a00";
            $("#btnAceptarEvento").css("display", "block");
            break;
    }
    return color;
}


function getTextAlarm(status) {
    return text = labelsLocale["alert" + status];
}
export { getTextAlarm }


function getDevices() {
    var user = JSON.parse(window.localStorage.getItem("user" + sufix));
    var userId = user.id;
    var url = window.localStorage.getItem("CtlUrlWSIoT" + sufix) + "/getListDispositivosByUser/" + userId;
    $.ajax({
        type: 'post',
        url: url,
        crossDomain: true,
        async: true,
        cache: false,
        headers: {
            "cache-control": "no-cache"
        },
        contentType: "application/json",
        success: function (data) {
            // console.log(data);
            if (data.length > 1) {
                getDeviceHTML(data[0]);
                //data = orderDevicesByStatus(data);
                //getDevicesHTML(data);

            }
            else {
                getDeviceHTML(data[0]);
                //getDeviceHTML(data);
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            var error = "";

            for (var aux in jqXHR) {
                error += " " + aux;
            }

            error = textStatus + " " + error + " " + errorThrown;
            showError(error);
        },
        complete: function (data, textStatus, errorThrown) {
            closeLoading();
        }
    });
}


